import PageLayout from "../../components/PageLayout"
import React from "react"
import {observer} from "mobx-react"
import {useRootStore} from "../../contexts"
import Link from "../../components/Link"

function CreateOrderCurrentLevelsPage() {
    const {routerStore, quarterStore} = useRootStore()

    return (
        <PageLayout title="" page={CreateOrderCurrentLevelsPage}>
            <div className="wrap content">
                <div className="container">
                    <div className="wrap d-flex ai-center title__block">
                        <h1>Тарифы</h1>
                    </div>
                    <div className="wrap d-flex item-grid item-grid-3 button__block">
                        {quarterStore.levels && quarterStore.levels.map(item => {
                            return <div className="item" key={item.id}>
                                <Link
                                    route={"createOrderCurrent"}
                                    options={{
                                        params: {level: item.id}
                                    }}
                                    className="wrap d-flex fd-column"
                                >
                                    <div className="wrap button-block__content">
                                        <p>{item.name}</p>
                                        <p className="text__small text__color-grey">{item.description}</p>
                                    </div>
                                </Link>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </PageLayout>
    )
}

export default observer(CreateOrderCurrentLevelsPage)
