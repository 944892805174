import PageLayout from "../../components/PageLayout"
import React from "react"
import {observer} from "mobx-react"
import {useRootStore} from "../../contexts"
import Link from "../../components/Link"

function RecoverPage() {
    const {routerStore, authStore} = useRootStore()
    const [recover, setRecover] = React.useState(false)

    const [values, setValues] = React.useState({
        login: "",
    })

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value})
    }

    const login = () => {
        if (values.login.trim() != "") {
            authStore.reset(values.login, function (){
                setRecover(true)
            })
        }
    }

    return (
        <div className="wrap" id="content__wrap">
            <div className="wrap d-flex ai-center login__block">

                {recover && <div className="login-block__wrap">
                    <h4>Восстановление пароля</h4>
                    <p className="text__small">На указанную почту была выслана инструкция по восстановлению пароля.</p>

                    <p className="text__extra-small" style={{textAlign: "right"}}>
                        <Link
                            route={"login"}
                        >
                            Вход
                        </Link>
                    </p>
                </div>}

                {!recover &&
                <div className="login-block__wrap">
                    <h4>Восстановление пароля</h4>
                    <p className="text__small">Введите почту, на которую зарегистрирован личный кабинет — мы отправим на нее инструкцию c дальнейшими
                        шагами.</p>
                    <div className="wrap text__mt-32">
                        <input
                            type="text"
                            placeholder="Введите e-mail"
                            onChange={handleChange("login")}
                        />

                        <input
                            type="submit"
                            value="Восстановить"
                            name="send"
                            onClick={login}
                        />
                    </div>

                    <br/>
                    <p className="text__extra-small" style={{textAlign: "right"}}>
                        <Link
                            route={"login"}
                        >
                            Отмена
                        </Link>
                    </p>
                </div>}

            </div>
        </div>
    )
}

export default observer(RecoverPage)
