import {makeAutoObservable, observable, reaction} from "mobx"
import {RootStore} from "./RootStore"
import demoarticles from "../demo/articles"

export interface Firm {
    id: number
    name: string
    inn: string
    okved: string[]
    okvedRaw: string
    total: number
    used: number
    number: number
    showOKVED?: boolean
}

export interface QuarterHead {
    id: number
    quarter: number
    year: number
}

export interface Level {
    id: number
    name: string
    description: string
}

export class QuarterStore {
    rootStore: RootStore
    firms: Firm[]
    firmsFiltered: Firm[]
    currentPage: number
    quarter: number
    close: boolean
    quarters: QuarterHead[]
    successUpload: boolean
    errorUpload: boolean
    filterOKVED: string
    level: number
    levels: Level[]
    currentLevel: Level

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            firms: observable,
            firmsFiltered: observable,
            quarter: observable,
            close: observable,
            successUpload: observable,
            errorUpload: observable,
            filterOKVED:observable,
            level: observable,
            levels: observable,
            currentLevel: observable
        })

        this.rootStore = rootStore
        this.firms = [] as Firm[]
        this.firmsFiltered = [] as Firm[]
        this.levels = [] as Level[]
        this.currentPage = 1
        this.quarter = 0
        this.level = 1
        this.close = false

        this.errorUpload = false
        this.successUpload = false

        this.currentLevel = {} as Level
        this.filterOKVED = ""
    }

    setShowOKVED = (id) => {
        this.firmsFiltered.filter(x => x.id == id)[0].showOKVED = true
        this.firms.filter(x => x.id == id)[0].showOKVED = true
    }

    setLevel = (level: number) => {
        this.level = level
        this.getLevels()
    }

    setQuarter = (quarter: number) => {
        this.currentPage = 0
        this.quarter = quarter
        this.loadFirms()
    }

    setPage = (page: number) => {
        this.currentPage = page
        this.loadFirms()
    }

    setFilterOKVED = (okved: string) => {
        if (okved.trim() == "") {
            this.firmsFiltered = this.firms
        } else {
            this.firmsFiltered = this.firms.filter(x => x.okvedRaw.includes(okved))
        }
    }

    loadFirms = async () => {
        this.errorUpload = false
        this.successUpload = false

        this.rootStore.API.call("/Quarter/Get", {
            page: this.currentPage,
            quarter: this.quarter,
            level: this.level
        }).then((response) => {
            if (response.data) {
                if (!!response.data.close) {
                    this.close = true
                    this.firms = [] as Firm[]
                    this.firmsFiltered = [] as Firm[]
                } else {
                    this.close = false
                    this.firms = response.data
                    this.firmsFiltered = response.data
                }
            }
        })
    }

    loadCorrections = async () => {
        this.rootStore.API.call("/Quarter/GetCorrections", {
            level: this.level
        })
            .then((response) => {
                if (response.data) {
                    this.quarters = response.data
                }
            })
    }

    getLevels = async () => {
        this.rootStore.API.call("/Quarter/GetLevels")
            .then((response) => {
                if (response.data) {
                    this.levels = response.data

                    this.currentLevel = response.data.filter(x => x.id == this.level)[0]
                }
            })
    }

    upload = (files: FileList) => {
        const data = new FormData()
        data.append("quarter", String(this.quarter))
        data.append("level", String(this.level))
        Array.from(files).forEach(file => {
            data.append("order[]", file, file.name)
        })

        this.rootStore.API.call("/Quarter/Upload", data)
            .then((response) => {
                this.successUpload = true
                this.errorUpload = false
            })
            .catch(error => {
                this.successUpload = false
                this.errorUpload = true
            })
    }

    downloadExample = async () => {
        this.rootStore.API.download("/Quarter/DownloadExample", "Образец.xls")
    }
}

