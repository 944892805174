import {makeAutoObservable, observable, reaction} from "mobx"
import {RootStore} from "./RootStore"


export interface Document {
    firm_inn: string
    firm_name: string
    id: number
    inn: string
    name: string
    ext: string
}

export interface Sign {
    id: number
    name: string
    status: string
}

export class DocumentStore {
    rootStore: RootStore

    documentList: Document[]
    signList: Sign[]

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            documentList: observable,
            signList: observable,
        })

        this.rootStore = rootStore
        this.documentList = [] as Document[]
        this.signList= [] as Sign[]
    }


    load = async () => {
        this.rootStore.API.call("/Document/GetAll").then((response) => {
            if (response.data) {
                this.documentList = response.data
            }
        })
    }

    loadSign = async () => {
        this.rootStore.API.call("/Document/GetSign").then((response) => {
            if (response.data) {
                this.signList = response.data
            }
        })
    }

    upload  = (files: FileList) => {
        const data = new FormData()
        Array.from(files).forEach(file => {
            data.append("file[]", file, file.name)
        })

        this.rootStore.API.call("/Document/Upload", data)
            .then((response) => {
                this.loadSign()
            })
            .catch(error => {

            })
    }

    downloadFile = async (id: number, name: string) => {
        this.rootStore.API.download("/Document/DownloadFile", name, {id: id})
    }

    downloadBook = async (id: number, name: string) => {
        this.rootStore.API.download("/Document/DownloadBook", name, {id: id})
    }
}

