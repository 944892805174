import {RootStore} from "../stores/RootStore"
import axios, {AxiosResponse, Method} from "axios"

const FileDownload = require("js-file-download")

const baseUrl = "https://api.tributum.pro/"//"http://localhost:99/"

export class API {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }


    download = (patch: string, name: string, params?: any) => {
        axios({
            baseURL: baseUrl,
            url: patch,
            method: "POST",
            data: params,
            responseType: "blob",
            withCredentials: true,
            headers: {
                "Accept": "application/json",
            },
        }).then((response) => {
            FileDownload(response.data, name)
        })
    }

    call = (method: string, params?: any, http?: Method) => {
        http = http || "post"
        let api = axios.create({
            baseURL: baseUrl,
            responseType: "json",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=utf-8",
            },
            withCredentials: true
        })

        let that = this


        return api.request({
            url: method,
            method: http,
            params: http === "get" ? params : {},
            data: http === "post" ? params : {},
            validateStatus: function (status) {
                return status === 200
            },
        })
            .catch(function (error) {
                console.log("ERROR")
                if (typeof error.response !== "undefined" && typeof error.response.status !== "undefined") {
                    if (error.response.status === 401) {
                        that.rootStore.routerStore.goTo("login")
                    } else {
                        console.log("ERROR1")
                    }
                } else {
                    console.log("ERROR2")
                    that.rootStore.routerStore.goTo("error")
                }
                return {} as AxiosResponse
            })
    }
}

