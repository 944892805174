import {makeAutoObservable, observable, reaction} from "mobx"
import {RootStore} from "./RootStore"

export interface Referral {
    id: number
    alias: string
    name: string
    percent: number
    percent_correction: number
    email: string
    summ: number
    summ_correction: number
    pay: number
    parents: number[]
}


export class ReferralStore {
    rootStore: RootStore
    referrals: Referral[]
    generatedLink: string

    error: string

    hash: string

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.referrals = [] as Referral[]
        this.generatedLink = ""
        this.error = ""

        this.hash = ""

        makeAutoObservable(this, {
            referrals: observable,
            generatedLink: observable,
            error: observable,
            hash: observable,
        })
    }


    loadReferrals = async () => {
        this.referrals = [] as Referral[]

        this.generatedLink = ""
        this.rootStore.API.call("/Referral/GetAll").then((response) => {
            if (response.data) {
                this.referrals = response.data
            }
        })
    }

    setHash = (hash: string) => {
        this.hash = hash
    }

    addReferral = (name: string, percent: string, percentCorrection: string): void => {
        let params = {
            name: name,
            percent: percent,
            percentCorrection: percentCorrection,
        }
        this.rootStore.API.call("/Referral/Add", params).then((response) => {
            if (response.data) {
                if (response.data.state == "ok") {
                    this.generatedLink = response.data.hash
                } else {
                    this.generatedLink = ""
                }
            } else {
                this.generatedLink = ""
            }
        })
    }

    registration = (password: string, password2: string, email: string) => {
        this.error = ""

        if (email.trim() == "") {
            this.error = "Email не заполнен"
            return
        }
        if (password.trim() == "") {
            this.error = "Пароль не может быть пустым"
            return
        }
        if (password.length < 5) {
            this.error = "Пароль слишком короткий"
            return
        }
        if (password != password2) {
            this.error = "Пароли не совпадают"
            return
        }

        let params = {
            hash: this.hash,
            password: password,
            email: email,
        }
        this.rootStore.API.call("/Referral/Registration", params).then((response) => {
            if (response.data) {
                if (response.data.state == "ok") {
                    this.rootStore.authStore.user = response.data.user
                    this.rootStore.routerStore.goTo("home")
                } else {
                    this.error = response.data.message
                }
            } else {
                this.error = "Ошибка запроса"
            }
        })
    }

    changePercent = (id: number, percent: number, percentCorrection: number) => {
        let params = {
            id: id,
            percent: percent,
            percentCorrection: percentCorrection,
        }
        this.rootStore.API.call("/Referral/Edit", params).then((response) => {

        })
    }

    getRefName = (id: number) => {
        let ref = this.referrals.filter(x => x.id == id)
        if (this.rootStore.authStore.user.id == id) {
            return "Я"
        } else {
            return ref.length > 0 ? ref[0].name : ""
        }
    }
}

