import PageLayout from "../../components/PageLayout"
import React, {useEffect} from "react"
import {observer} from "mobx-react"
import {useRootStore} from "../../contexts"
import CreateOrderCurrentDrawer from "./CreateOrderCurrentDrawer"
import {DataGrid} from "@mui/x-data-grid"
import {numberFormat} from "../../components/numberFormat"
import {Pagination} from "@material-ui/lab"

function CreateOrderCurrent() {
    const {routerStore, quarterStore} = useRootStore()
    const [openDrawer, setOpenDrawer] = React.useState(false)
    const [showFilter, setShowFilter] = React.useState(false)

    useEffect(() => {
        document.body.classList.toggle("__hide-all", openDrawer)

        quarterStore.errorUpload = false
        quarterStore.successUpload = false
    }, [openDrawer])

    return (
        <PageLayout title="" page={CreateOrderCurrent} drawer={<CreateOrderCurrentDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/>}>

            <div className="wrap content">
                <div className="container">
                    {quarterStore.close && <div>Квартал закрыт</div>}
                    {!quarterStore.close && <div>

                        <div className="wrap d-flex ai-center title__block">
                            <h1>Текущий квартал ({quarterStore.currentLevel.name})</h1>
                            <span
                                className="link__button link-button__excel"
                                onClick={quarterStore.downloadExample}
                            ><span>Скачать образец заявки</span></span>
                            <div className="link__button link-button__dark show__hidden-section" onClick={() => setOpenDrawer(true)}>
                                <span>Отправить в работу</span>
                            </div>
                        </div>
                        <div className="wrap table__wrap">
                            <table className="">
                                <tbody>
                                    <tr className="table-wrap__header">
                                        <td>#</td>
                                        <td>Фирма</td>
                                        <td>ИНН</td>
                                        <td>
                                            <div className="table-wrap__filter">
                                                <div className="table-wrap__filter-name">ОКВЭД</div>
                                                <div className="table-wrap__filter-icon" onClick={() => {
                                                    quarterStore.setFilterOKVED("")
                                                    setShowFilter(!showFilter)
                                                }}></div>
                                                <div className={"table-wrap__filter-wrap " + (showFilter ? "__show" : "")}>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            name="search"
                                                            placeholder="Введите..."
                                                            onChange={(event) => {
                                                                quarterStore.setFilterOKVED(event.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Доступный объем</td>
                                    </tr>
                                    {quarterStore.firmsFiltered.map((item, i) => {
                                        return <tr key={i}>
                                            <td>{item.number}</td>
                                            <td>{item.name}</td>
                                            <td>{item.inn}</td>
                                            <td>
                                                {item.okved.slice(0, 2).map((okved, o) => {
                                                    return <span key={o}>{okved}, </span>
                                                })}
                                                {item.showOKVED &&
                                                <div style={{display: "inline"}}>
                                                    {item.okved.slice(2).map((okved, o) => {
                                                        return <span key={o}>{okved}, </span>
                                                    })}
                                                </div>
                                                }
                                                {!item.showOKVED &&
                                                    <span onClick={() => {
                                                        quarterStore.setShowOKVED(item.id)
                                                    }}>...</span>
                                                }
                                            </td>
                                            <td>
                                                {(item.total - item.used) >= 50000000 &&
                                                <span className="table-wrap__tag table-wrap__tag-available">Доступно</span>
                                                }
                                                {(item.total - item.used) < 50000000 &&
                                                <span className="table-wrap__tag table-wrap__tag-volume">Объем {numberFormat(item.total, null, ",", ".")}</span>
                                                }
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>

                            <Pagination
                                count={quarterStore.firmsFiltered.length / 25}
                                hideNextButton={true}
                                hidePrevButton={true}
                                className={"table__pagination"}
                                defaultPage={quarterStore.currentPage}
                                onChange={(event: object, page: number) => {
                                    if (page != quarterStore.currentPage) {
                                        quarterStore.setPage(page)
                                    }
                                }}
                            />

                        </div>

                    </div>}


                </div>
            </div>
        </PageLayout>
    )
}

export default observer(CreateOrderCurrent)
