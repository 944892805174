import PageLayout from "../../components/PageLayout"
import React, {useRef} from "react"
import {observer} from "mobx-react"
import {useRootStore} from "../../contexts"
import Link from "../../components/Link"

interface CreateOrderCurrentDrawerProps {
    openDrawer?: boolean
    setOpenDrawer: any
}


function CreateOrderCurrentDrawer(props: CreateOrderCurrentDrawerProps) {
    const {quarterStore, routerStore} = useRootStore()

    const inputRef = useRef(null)

    const [files, setFiles] = React.useState<FileList>()

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFiles(e.target.files)
        }
    }

    const handleUploadClick = () => {
        console.log(files)
        if (!files) {
            return
        }

        quarterStore.upload(files)
        setFiles(null)
        inputRef.current.value = null
    }

    return (
        <div className={"section__hidden " + (props.openDrawer && "__show")}>
            <div className="section-hidden__close" onClick={() => props.setOpenDrawer(false)}></div>
            <div className="wrap section-hidden__content">
                <h3>Проверка и отправка в работу</h3>
                <p className="text__small">Воспользуйтесь формой ниже для загрузки файла.<br/>Если файл заполнен корректно, то он автоматически будет принят в
                    работу. Если в файле будут обнаружены ошибки, вы будете проинформированы об их наличии и местонахождении.</p>
                <div className="wrap">
                    <div className="wrap field__wrapper">
                        <input
                            ref={inputRef} name="file" type="file" id="field__file-2" className="field field__file" accept=".xls" multiple
                            onChange={handleFileChange}/>
                        <label className="field__file-wrapper" htmlFor="field__file-2">
                            <div className="field__file-fake">
                                {(!files || files.length == 0) && "Файл не выбран"}
                                {!!files && files.length == 1 && files[0].name}
                                {!!files && files.length > 1 && "Выбрано " + files.length + " файлов"}
                            </div>
                            <div className="field__file-button">Выбрать</div>
                        </label>
                    </div>
                    <input type="button" value="Отправить" className={"submit"} name="send" onClick={handleUploadClick}/>
                </div>

                {quarterStore.errorUpload &&
                <div className="wrap file__check file-check__error">
                    <p><b>Ошибка загрузки!</b></p>
                    <p className="text__small">Не удалось загрузить файл, попробуйте позже или обратитесь к администратору</p>
                </div>}

                {quarterStore.successUpload &&
                <div className="wrap file__check file-check__success">
                    <p><b>Файлы загружены и отправлены в обработку!</b></p>
                    <p className="text__small">Скачать таблицу с номерами Вы можете во вкладке <Link route={"myOrders"} className={"link__text-bottom"}>«Мои
                        заказы и оплаты»</Link>.
                    </p>
                </div>}

            </div>
        </div>
    )
}

export default observer(CreateOrderCurrentDrawer)
