const numberFormat = (number, decimals = null, dec_point = ",", thousands_sep = " ") => {
    let i, j, kw, kd, km, minus = ""
    if (number < 0) {
        minus = "-"
        number = number * -1
    }

    if (decimals == null) {
        if (Math.trunc(number) == number) {
            decimals = 0
        } else {
            decimals = 2
        }
    }
    if (dec_point == undefined) {
        dec_point = ","
    }
    if (thousands_sep == undefined) {
        thousands_sep = "."
    }

    i = parseInt(number = (+number || 0).toFixed(decimals)) + ""

    if ((j = i.length) > 3) {
        j = j % 3
    } else {
        j = 0
    }

    km = (j ? i.substr(0, j) + thousands_sep : "")
    kw = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands_sep)
    kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, "0").slice(2) : "")
    return minus + km + kw + kd
}

const numWord = (value, words) => {
    value = Math.abs(value) % 100
    var num = value % 10
    if (value > 10 && value < 20) return words[2]
    if (num > 1 && num < 5) return words[1]
    if (num == 1) return words[0]
    return words[2]
}

export {numberFormat, numWord}
