import {RootStore} from "./RootStore"
import {makeAutoObservable, observable} from "mobx"

interface User {
    id: number
    name: string
    email: string
    level: number
    percent: number
    percent_correction: number
}

export class AuthStore {
    rootStore: RootStore
    user: User

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.user = {} as User
        makeAutoObservable(this, {
            user: observable,
        })

        this.checkUser()
    }

    login = (user, password) => {
        let params = {login: user, password: password}
        let that = this
        this.rootStore.API.call("/Client/SignIn", params).then((response) => {
            console.log(response.data)
            if (response.data) {
                let data = response.data
                if (data.success) {
                    that.user = response.data.user
                    this.rootStore.routerStore.goTo("home")
                }
            }
        })
    }

    reset = (user, callback: any) => {
        let params = {login: user}
        this.rootStore.API.call("/Client/Reset", params).then((response) => {
            callback()
        })
    }

    logout = () => {
        this.rootStore.API.call("/Client/Logout").then((response) => {
            this.rootStore.routerStore.goTo("login")
        })
    }

    loadClient = async () => {
        let that = this
        this.rootStore.API.call("/Client/GetInfo")
            .then((response) => {
                console.log(response.data)
                that.user = response.data
                that.rootStore.appLoading = false
            })
            .catch(error => {
                that.user = {} as User
                that.rootStore.appLoading = false
            })
    }

    checkUser = async () => {
        let that = this
        await this.rootStore.API.call("/Client/CheckUser")
            .then((response) => {
                if (typeof response.data.name != "undefined") {
                    that.user = response.data
                    if (this.rootStore.routerStore.routerState.routeName == "login" || this.rootStore.routerStore.routerState.routeName == "activate") {
                        this.rootStore.routerStore.goTo("home")
                    }
                } else {
                    if (this.rootStore.routerStore.routerState.routeName != "login" && this.rootStore.routerStore.routerState.routeName != "activate") {
                        this.rootStore.routerStore.goTo("login")
                    }
                }
            })
            .catch(error => {
                that.user = {} as User
                that.rootStore.appLoading = false
            })
    }
}
