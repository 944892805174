import React, {ReactNode, useEffect} from "react"
import {ScrollingContainer, ViewVerticalContainer} from "@react-force/core"
import {Header} from "../Header"
import {observer} from "mobx-react-lite"
import Link from "../Link"

export interface IProps {
    backButton?: boolean
    homeButton?: boolean
    title?: string
    page?: any
    children?: ReactNode | ReactNode[]
    className?: string
    hideHeader?: boolean
    drawer?: ReactNode
}

function PageLayout(props: IProps) {

    // use page title to produce readable browser history
    useEffect(() => {
        if (document && props.title) {
            document.title = props.title
        }
    })

    return (
        <div id="wrapper" style={{display: "flex", flexDirection: "column"}}>
            <Header {...props} />

            <div className="wrap" id="content__wrap">
                {props.children}
            </div>

            {props.drawer}

            <div className="wrap" style={{flex: 1, display: "flex", justifyContent: "flex-end", flexDirection: "column"}}>
                <div className="container" style={{margin: "20px 0"}}>
                    <div className="wrap d-flex ai-center">
                        <div>
                            <p>Инструкция по работе с сайтом: <Link route={"help"}>Видео</Link>, <Link route={"helpText"}>Текст</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(PageLayout)
