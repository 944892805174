import PageLayout from "../../components/PageLayout"
import React, {useEffect, useRef} from "react"
import {observer} from "mobx-react"
import {useRootStore} from "../../contexts"
import Link from "../../components/Link"
import {CopyToClipboard} from "react-copy-to-clipboard"

interface MyReferralstDrawerProps {
    openDrawer?: boolean
    setOpenDrawer: any
}


function MyReferralstDrawer(props: MyReferralstDrawerProps) {
    const {referralStore, routerStore, authStore} = useRootStore()

    const [name, setName] = React.useState("")
    const [percent, setPercent] = React.useState("")
    const [percentCorrection, setPercentCorrection] = React.useState("")
    const [percentError, setPercentError] = React.useState("")
    const [percentCorrectionError, setPercentCorrectionError] = React.useState("")
    const [copied, setCopied] = React.useState(false)

    const changeName = (event) => {
        referralStore.generatedLink = ""
        setName(event.target.value)
    }

    const changePercent = (event) => {
        setPercentError("")
        referralStore.generatedLink = ""
        let tmp = event.target.value
        tmp = tmp.replace(",", ".").replace(/[^\d.]/g, "")

        if (Number.parseFloat(tmp) < authStore.user.percent) {
            tmp = 0
        }
        if (Number.parseFloat(tmp) > 100) {
            tmp = 100
        }

        if (Number.parseFloat(tmp) != tmp) {
            setPercentError("Некорректное число")
        }
        setPercent(tmp)
    }

    const changePercentCorrection = (event) => {
        setPercentCorrectionError("")
        referralStore.generatedLink = ""
        let tmp = event.target.value
        tmp = tmp.replace(",", ".").replace(/[^\d.]/g, "")

        if (Number.parseFloat(tmp) < authStore.user.percent_correction) {
            tmp = 0
        }
        if (Number.parseFloat(tmp) > 100) {
            tmp = 100
        }
        if (Number.parseFloat(tmp) != tmp) {
            setPercentCorrectionError("Некорректное число")
        }
        setPercentCorrection(tmp)
    }

    const generateLink = () => {
        if (name.trim() != "") {
            referralStore.addReferral(name, percent, percentCorrection)
            setName("")
            setPercent("")
            setPercentCorrection("")
        }
    }

    useEffect(() => {
        let timer1 = setInterval(() => setCopied(false), 5000)
        return () => {
            clearInterval(timer1)
        }
    }, [setCopied])

    return (
        <div className={"section__hidden " + (props.openDrawer && "__show")}>
            <div className="section-hidden__close" onClick={() => {
                referralStore.generatedLink = ""
                setName("")
                props.setOpenDrawer(false)
            }}></div>
            <div className="wrap section-hidden__content">
                <h3>Добавление<br/>реферала</h3>
                <p>Реферал не будет видеть стоимость услуги, его кабинет создан исключительно для добавления заявок.</p>
                <div className="wrap">
                    <input type="text" placeholder="Псевдоним" onChange={changeName} value={name}/>
                    <input type="text" placeholder={"% за услугу (минимум " + authStore.user.percent + "%)"} onInput={changePercent} value={percent}/>
                    {percentError != "" && <div style={{color: "#ff0000", marginBottom: 10}}>{percentError}</div>}
                    <input type="text" placeholder={"% за услугу уточнения (минимум " + authStore.user.percent_correction + "%)"} onInput={changePercentCorrection} value={percentCorrection}/>
                    {percentCorrectionError != "" && <div style={{color: "#ff0000", marginBottom: 10}}>{percentCorrectionError}</div>}
                    <input type="button" className={"submit"} value="Далее" onClick={generateLink} disabled={percentError != "" || percentCorrectionError != ""}/>
                </div>

                {referralStore.generatedLink != "" &&
                <div className="wrap referral__info">
                    <p>Реферал добавлен</p>
                    <p className="text__small">Предоставьте эту ссылку рефералу для дальнейшей регистрации.<br/>
                        Код действует в течение 3 часов.</p>
                    <div className="wrap d-flex referral-info__wrap">
                        <div className="referral-info__code">https://tributum.pro/activate/{referralStore.generatedLink}</div>
                        <CopyToClipboard text={"https://tributum.pro/activate/" + referralStore.generatedLink} onCopy={() => {
                            setCopied(true)
                        }}>
                            <button className="referral-info__button copy__button" title="Копировать"></button>
                        </CopyToClipboard>
                        <div className="wrap referral-info__msg text__extra-small text__center text__color-green" style={{
                            opacity: copied ? 1 : 0
                        }}>Ссылка успешно скопирована
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default observer(MyReferralstDrawer)
