import React from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../contexts"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"
import {useRouterStore} from "mobx-state-router"
import Link from "../Link"


export interface IProps {
    backButton?: boolean
    homeButton?: boolean
    title?: string
    page?: any
}

function CustomHeader(props: IProps) {
    const {routerStore, authStore} = useRootStore()
    const [open, setOpen] = React.useState(false)
    const [openMenu, setOpenMenu] = React.useState(false)

    console.log(props)

    return (
        <>
            <header>
                <div className="wrap">
                    <div className="container">
                        <div className="wrap d-flex ai-center">
                            <Link route={"home"} className={"logo logo__project"}>
                                <span>tributum</span>
                            </Link>
                            <ul className="main__menu d-flex">
                                <li className={["HomePage", "LevelPage", "CreateOrderCurrent", "CreateOrderCorrections", "CreateOrderCorrection"].includes(props.page.name) ? "active" : ""}>
                                    <Link route={"home"}>Создать заказ</Link>
                                    <ul>
                                        <li>
                                            <Link route={"createOrderCurrentLevels"}>Текущий квартал</Link>
                                        </li>
                                        <li>
                                            <Link route={"createOrderCorrections"}>Уточнения</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={["MyOrders", "MyOrder" ].includes(props.page.name) ? "active" : ""}>
                                    <Link route={"myOrders"} className={""}>Мои заказы и оплаты</Link>
                                </li>
                                <li className={["Payment" ].includes(props.page.name) ? "active" : ""}>
                                    <Link route={"payment"} className={""}>Оплата</Link>
                                </li>
                                <li className={["MyDoc" ].includes(props.page.name) ? "active" : ""}>
                                    <Link route={"myDoc"} className={"main-menu__icon main-menu__icon-mydoc"}>Мои документы</Link>
                                </li>
                                <li className={["Support" ].includes(props.page.name) ? "active" : ""}>
                                    <Link route={"support"} className={""}>Техподдержка</Link>
                                </li>
                            </ul>
                            <div className="my__account d-flex ai-center">
                                <div className="my-account__avatar"></div>
                                <div className="my-account__name">{authStore.user.email}</div>
                            </div>
                            <div className="my__conditions">
                                <div className="my-conditions__close"></div>
                                <div className="my-conditions__content wrap">
                                    <h5>Мои условия</h5>
                                    <p>На 2 и 3 кварталы 2023 года устанавливается льготная ставка:</p>
                                    <ul className="ul__list">
                                        <li>на текущие — 0.6%</li>
                                        <li>на уточнения — 0.8%</li>
                                    </ul>
                                    <p className="text__mt-big">С 4 квартала 2023 года ставка будет зависеть от объема, закрытого в 2 и 3 кварталах:</p>
                                    <ul className="ul__list">
                                        <li>объём до 100 млн — 2%</li>
                                        <li>100 млн – 500 млн — 1.5%</li>
                                        <li>500 млн – 1.000 млн — 1%</li>
                                        <li>более 1.000 млн — 0.6%</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="header__side d-flex ai-center">
                                {authStore.user.level < 2 &&
                                <Link route={"myReferrals"} className={"header-side__invite"} htmlOptions={{title: "Пригласить"}}></Link>
                                }
                                <span className={"header-side__logout"} title={"Выйти"} onClick={authStore.logout}></span>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export const Header = observer(CustomHeader)
