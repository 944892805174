import PageLayout from "../../components/PageLayout"
import React from "react"
import {observer} from "mobx-react"
import {useRootStore} from "../../contexts"


function Support() {
    const {routerStore, authStore} = useRootStore()



    return (
        <PageLayout title="" page={Support}>
            <div className="wrap content">
                <div className="container">

                    <div className="wrap d-flex ai-center title__block">
                        <h1>Техподдержка</h1>
                    </div>

                    <div style={{marginBottom: 40}}>
                        <div className="wrap text__mt-32">
                            <input type="text" placeholder="Тема сообщения"/>
                            <textarea placeholder="Текст сообщения"/>
                            <input type="button" className={"submit"} value="Отправить" onClick={() => {}}/>
                        </div>
                    </div>

                    <div className="wrap table__wrap">
                        <table className="">
                            <thead>
                                <tr className="table-wrap__header">
                                    <th>#</th>
                                    <th>Дата обращения</th>
                                    <th>Тема</th>
                                    <th>Статус</th>
                                </tr>
                            </thead>
                            <tbody className="">

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </PageLayout>
    )
}

export default observer(Support)
