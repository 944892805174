import PageLayout from "../../components/PageLayout"
import React from "react"
import {observer} from "mobx-react"
import {useRootStore} from "../../contexts"
import Link from "../../components/Link"

function ErrorPage() {
    const {routerStore} = useRootStore()

    return (
        <div className={"centred"}>
            <div className={"title"}>Ошибка</div>
            <div>
                Что-то пошло не так
            </div>
            <div>
                Попробуйте вернуться на <Link route={"home"} className={""}>главную страницу</Link>
            </div>
        </div>
    )
}

export default observer(ErrorPage)
