import PageLayout from "../../components/PageLayout"
import React, {useEffect} from "react"
import {observer} from "mobx-react"
import {useRootStore} from "../../contexts"
import Link from "../../components/Link/Link"

function LoginPage() {
    const {routerStore, authStore} = useRootStore()
    const [openDrawer, setOpenDrawer] = React.useState(false)

    const [values, setValues] = React.useState({
        login: "",
        password: "",
        showPassword: false,
    })

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value})
    }

    const handleClickShowPassword = () => {
        setValues({...values, showPassword: !values.showPassword})
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const login = () => {
        authStore.login(values.login, values.password)
    }

    return (
        <div className="wrap" id="content__wrap">
            <div className="wrap d-flex ai-center login__block">

                <div className="login-block__wrap">
                    <h4>Авторизация</h4>

                    <div className={"form"}>
                        <input
                            type="text"
                            placeholder="Введите логин или e-mail"
                            onChange={handleChange("login")}
                        />
                        <input
                            type="password"
                            placeholder="Введите пароль"
                            onChange={handleChange("password")}
                        />
                        <input
                            className={"submit"}
                            type="button"
                            value="Войти"
                            name="send"
                            onClick={login}
                        />
                    </div>

                    <p className="text__extra-small">
                        <Link
                            route={"recover"}
                        >
                            Восстановить пароль
                        </Link>
                    </p>
                </div>

            </div>
        </div>
    )
}

export default observer(LoginPage)
