import React, {ReactNode} from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../contexts"

export interface IProps {
    route: string
    options?: any
    children?: ReactNode | ReactNode[]
    className?: string
    htmlOptions?: any
}

function Link(props: IProps) {
    const {routerStore,} = useRootStore()

    return (
        <span
            className={"link " + props.className}
            {...props.htmlOptions}
            onClick={() => {
                console.log("click")
                routerStore.goTo(props.route, props.options)
            }}>
            {props.children}
        </span>
    )
}
export default observer(Link)