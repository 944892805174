import PageLayout from "../../components/PageLayout"
import React, {useRef} from "react"
import {observer} from "mobx-react"
import {useRootStore} from "../../contexts"

function MyDoc() {
    const {routerStore, documentStore} = useRootStore()

    const inputRef = useRef(null)

    const [files, setFiles] = React.useState<FileList>()

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFiles(e.target.files)
        }
    }

    const handleUploadClick = () => {
        console.log(files)
        if (!files) {
            return
        }

        documentStore.upload(files)
        setFiles(null)
        inputRef.current.value = null
    }

    return (
        <PageLayout title="" page={MyDoc}>

            <div className="wrap content">
                <div className="container">
                    <div className="wrap d-flex ai-center title__block">
                        <h1>Мои документы</h1>
                        <div className="title-block__text">
                            В течение 15 дней с даты сдачи деклараций тут будут<br/>появляться книги продаж по сданным отчетам
                        </div>
                    </div>
                    <div className="wrap table__wrap">

                    </div>
                </div>
            </div>

        </PageLayout>
    )
}

export default observer(MyDoc)
