import {RouterStore, createRouterState} from "mobx-state-router"
import {autorun} from "mobx"
import {routes} from "./routes"
import {API} from "../components/API"
import {AuthStore} from "./AuthStore"
import {QuarterStore} from "./QuarterStore"
import {OrderStore} from "./OrderStore"
import {DocumentStore} from "./DocumentStore"
import {ReferralStore} from "./ReferralStore"

const notFound = createRouterState("notFound")

export class RootStore {
    public quarterStore = new QuarterStore(this)
    public orderStore = new OrderStore(this)
    public documentStore = new DocumentStore(this)
    public referralStore = new ReferralStore(this)
    public API = new API(this)
    public routerStore = new RouterStore(routes, notFound, {
        rootStore: this
    })

    public authStore = new AuthStore(this)

    public appLoading = true

    constructor() {
        autorun(() => console.log("Entered route: " + this.routerStore.routerState.routeName))
        this.appLoading = true
        if (this.routerStore.routerState.routeName != "activate" && this.routerStore.routerState.routeName != "__initial__") {
            this.authStore.loadClient()
        }
    }
}
