import PageLayout from "../../components/PageLayout"
import React, {useEffect} from "react"
import {observer} from "mobx-react"
import {useRootStore} from "../../contexts"

function Activate() {
    const {routerStore, referralStore} = useRootStore()

    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [password2, setPassword2] = React.useState("")

    const changeEmail = (event) => {
        setEmail(event.target.value)
    }
    const changePassword = (event) => {
        setPassword(event.target.value)
    }
    const changePassword2 = (event) => {
        setPassword2(event.target.value)
    }

    const activate = () => {
        referralStore.registration(password, password2, email)
    }

    return (
        <div className="wrap" id="content__wrap">
            <div className="wrap d-flex ai-center login__block">
                <div className="login-block__wrap">
                    <h4>Регистрация</h4>
                    <p className="text__small">Введите свой e-mail и придумайте пароль для начала работы с порталом.</p>
                    <div className="wrap text__mt-32">
                        <input type="text" placeholder="Введите e-mail" onChange={changeEmail}/>
                        <input type="password" placeholder="Придумайте пароль" onChange={changePassword}/>
                        <input type="password" placeholder="Подтвердите пароль" onChange={changePassword2}/>
                        <input type="button" className={"submit"} value="Зарегистрироваться" onClick={activate}/>
                    </div>
                    {referralStore.error != "" && <div style={{color: "#ff0000"}}>{referralStore.error}</div>}
                </div>
            </div>
        </div>
    )
}

export default observer(Activate)
