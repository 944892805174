import PageLayout from "../../components/PageLayout"
import React from "react"
import {observer} from "mobx-react"
import {useRootStore} from "../../contexts"


function Payment() {
    const {routerStore, authStore} = useRootStore()



    return (
        <PageLayout title="" page={Payment}>
            <div className="wrap content">
                <div className="container">

                    <div className="wrap d-flex ai-center title__block">
                        <h1>Оплата</h1>
                    </div>

                </div>
            </div>

        </PageLayout>
    )
}

export default observer(Payment)
