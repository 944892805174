import PageLayout from "../../components/PageLayout"
import React, {useEffect} from "react"
import {observer} from "mobx-react"
import {useRootStore} from "../../contexts"
import MyReferralstDrawer from "./MyReferralstDrawer"
import {numberFormat} from "../../components/numberFormat"

function MyReferrals() {
    const {routerStore, referralStore} = useRootStore()
    const [openDrawer, setOpenDrawer] = React.useState(false)

    const [editable, setEditable] = React.useState(0)

    const [editPercent, setEditPercent] = React.useState(0)
    const [editPercentCorrection, setEditPercentCorrection] = React.useState(0)
    const [editStatus, setEditStatus] = React.useState(0)

    const changePercent = (event) => {
        let tmp = event.target.value
        tmp = tmp.replace(",", ".").replace(/[^\d.]/g, "")

        if (Number.parseFloat(tmp) < 0) {
            tmp = 0
        }
        if (Number.parseFloat(tmp) > 100) {
            tmp = 100
        }

        setEditPercent(tmp)
    }

    const changePercentCorrection = (event) => {
        let tmp = event.target.value
        tmp = tmp.replace(",", ".").replace(/[^\d.]/g, "")

        if (Number.parseFloat(tmp) < 0) {
            tmp = 0
        }
        if (Number.parseFloat(tmp) > 100) {
            tmp = 100
        }

        setEditPercentCorrection(tmp)
    }

    const saveChanges = () => {
        let item = referralStore.referrals.filter(x => x.id == editable)[0]
        item.percent = editPercent
        item.percent_correction = editPercentCorrection

        referralStore.referrals.filter(x => x.id == editable)[0] = item

        setEditable(0)
        setEditPercent(0)
        setEditPercentCorrection(0)

        referralStore.changePercent(editable, editPercent, editPercentCorrection)
    }

    useEffect(() => {
        document.body.classList.toggle("__hide-all", openDrawer)
    }, [openDrawer])

    return (
        <PageLayout title="" page={MyReferrals} drawer={<MyReferralstDrawer setOpenDrawer={setOpenDrawer} openDrawer={openDrawer}/>}>

            <div className="wrap content">
                <div className="container">
                    <div className="wrap d-flex ai-center title__block">
                        <h1>Мои рефералы</h1>
                        <div className="link__button link-button__dark show__hidden-section" onClick={() => setOpenDrawer(true)}>
                            <span>Пригласить</span>
                        </div>
                    </div>
                    <div className="wrap table__wrap">
                        <table className="">
                            <tbody className="">
                                <tr className="table-wrap__header">
                                    <td>#</td>
                                    <td>Рефералл</td>
                                    <td>Объем<br/>внутри квартала</td>
                                    <td>% за услугу</td>
                                    <td>Объем<br/>уточнения</td>
                                    <td>% за услугу<br/>уточнения</td>
                                    <td>Сумма<br/>к оплате</td>
                                    <td>Статус</td>
                                    <td></td>
                                </tr>
                                {referralStore.referrals.length > 0 && referralStore.referrals.map((item) => {
                                    return <tr key={item.id}>
                                        <td>#{item.id}</td>
                                        <td>
                                            {item.parents.map(it => {
                                                return referralStore.getRefName(it) + " <- "
                                            })}
                                            {item.name}
                                        </td>
                                        <td>{numberFormat(item.summ)}</td>
                                        <td>
                                            {editable == item.id && <div>
                                                <input
                                                    type="text"
                                                    value={editPercent}
                                                    style={{
                                                        width: 80,
                                                        padding: "0px 8px"
                                                    }}
                                                    onInput={changePercent}
                                                />
                                            </div>}
                                            {editable != item.id && <div onClick={() => {
                                                setEditPercent(item.percent)
                                                setEditPercentCorrection(item.percent_correction)
                                                setEditable(item.id)
                                            }}>
                                                {item.percent}
                                            </div>}
                                        </td>
                                        <td>{numberFormat(item.summ_correction)}</td>
                                        <td>
                                            {editable == item.id && <div>
                                                <input
                                                    type="text"
                                                    value={editPercentCorrection}
                                                    style={{
                                                        width: 80,
                                                        padding: "0px 8px"
                                                    }}
                                                    onInput={changePercentCorrection}
                                                />
                                            </div>}
                                            {editable != item.id && <div onClick={() => {
                                                setEditPercent(item.percent)
                                                setEditPercentCorrection(item.percent_correction)
                                                setEditable(item.id)
                                            }}>
                                                {item.percent_correction}
                                            </div>}
                                        </td>
                                        <td>{numberFormat(item.pay)}</td>
                                        <td></td>
                                        <td>
                                            {editable == item.id && <div>
                                                <input
                                                    type="button"
                                                    value={"Сохранить"}
                                                    onClick={saveChanges}
                                                />
                                            </div>}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </PageLayout>
    )
}

export default observer(MyReferrals)
