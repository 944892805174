import PageLayout from "../../components/PageLayout"
import React from "react"
import {observer} from "mobx-react"
import {useRootStore} from "../../contexts"


function HelpText() {
    const {routerStore, authStore} = useRootStore()



    return (
        <PageLayout title="" page={HelpText}>
            <div className="wrap content">
                <div className="container">

                    <div className="wrap d-flex ai-center title__block">
                        <h1>Инструкция по работе с сайтом</h1>
                    </div>

                </div>
            </div>

        </PageLayout>
    )
}

export default observer(HelpText)
