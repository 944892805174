import {makeAutoObservable, observable, reaction} from "mobx"
import {RootStore} from "./RootStore"

export interface Task {
    id: number
    file: string
    status: string
    date_create: string
    level_id: number
    user_id: number
    summ: number
    parents: number[]
    parentsNamed: { id: number, name: string, need_pay: number, is_first: boolean }[]
    referral: string
    cnt: number
    cnt_pay: number
    cnt_pay_confirmed: number
}

export interface Order {
    firm_inn: string
    firm_name: string
    id: number
    inn: string
    kpp: string
    name: string
    summ: number
    need_pay: number
    status_id: number
    confirmed_status_id: number
    referral: string
    parents: number[]
    parentsNamed: { id: number, name: string, need_pay: number, is_first: boolean }[]
    user_id: number
    checked: boolean
    total?: number
}

export class OrderStore {
    rootStore: RootStore
    tasks: Task[]
    tasksFiltered: Task[]
    task: Task
    currentTask: number
    orderList: Order[]
    orderListFiltered: Order[]

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            tasks: observable,
            tasksFiltered: observable,
            orderList: observable,
            orderListFiltered: observable,
            currentTask: observable,
            task: observable
        })

        this.rootStore = rootStore
        this.currentTask = 0
        this.tasks = [] as Task[]
        this.tasksFiltered = [] as Task[]
        this.task = {} as Task
        this.orderList = [] as Order[]
        this.orderListFiltered = [] as Order[]
    }

    setChecked = (id, check) => {
        this.orderList.filter(x => x.id == id)[0].checked = check
        this.orderListFiltered.filter(x => x.id == id)[0].checked = check
    }

    setFilterReferral = (referral: string) => {
        if (referral.trim() == "") {
            this.orderListFiltered = this.orderList
        } else {
            this.orderListFiltered = this.orderList.filter(x => x.referral.includes(referral) || x.parentsNamed.filter(y => y.name.includes(referral)).length > 0)
        }
    }

    setFilterName = (name: string) => {
        if (name.trim() == "") {
            this.orderListFiltered = this.orderList
        } else {
            this.orderListFiltered = this.orderList.filter(x => x.name.toLowerCase().includes(name.toLowerCase()) || x.inn.toLowerCase().includes(name.toLowerCase()))
        }
    }

    setFilterFirmName = (name: string) => {
        if (name.trim() == "") {
            this.orderListFiltered = this.orderList
        } else {
            this.orderListFiltered = this.orderList.filter(x => x.firm_name.toLowerCase().includes(name.toLowerCase()) || x.firm_inn.toLowerCase().includes(name.toLowerCase()))
        }
    }

    setFilterConfirmedStatus = (status: number) => {
        if (status == 0) {
            this.orderListFiltered = this.orderList
        } else {
            this.orderListFiltered = this.orderList.filter(x => x.confirmed_status_id == status)
        }
    }

    setFilterStatus = (status: number) => {
        if (status == 0) {
            this.orderListFiltered = this.orderList
        } else {
            this.orderListFiltered = this.orderList.filter(x => x.status_id == status)
        }
    }

    setFilterReferralTask = (referral: string) => {
        if (referral.trim() == "") {
            this.tasksFiltered = this.tasks
        } else {
            this.tasksFiltered = this.tasks.filter(x => x.referral.toLowerCase().includes(referral.toLowerCase()) || x.parentsNamed.filter(y => y.name.toLowerCase().includes(referral.toLowerCase())).length > 0)
        }
    }
    setFilterConfirmedStatusTask = (status: number) => {
        switch (status) {
        case 0:
            this.tasksFiltered = this.tasks
            break
        case 1:
            this.tasksFiltered = this.tasks.filter(x => x.cnt_pay_confirmed == 0 && x.cnt > 0)
            break
        case 2:
            this.tasksFiltered = this.tasks.filter(x => x.cnt_pay_confirmed == x.cnt && x.cnt > 0)
            break
        case 3:
            this.tasksFiltered = this.tasks.filter(x => x.cnt_pay_confirmed != x.cnt && x.cnt > 0)
            break
        }
    }

    setFilterStatusTask = (status: number) => {
        switch (status) {
        case 0:
            this.tasksFiltered = this.tasks
            break
        case 1:
            this.tasksFiltered = this.tasks.filter(x => x.cnt_pay == 0 && x.cnt > 0)
            break
        case 2:
            this.tasksFiltered = this.tasks.filter(x => x.cnt_pay == x.cnt && x.cnt > 0)
            break
        case 3:
            this.tasksFiltered = this.tasks.filter(x => x.cnt_pay != x.cnt && x.cnt > 0)
            break
        }
    }


    loadTasks = async () => {
        this.tasks = [] as Task[]
        this.rootStore.API.call("/Order/Tasks").then((response) => {
            if (response.data) {
                this.tasks = response.data
                this.tasksFiltered = response.data
            }
        })
    }

    loadTask = async (id) => {
        this.tasks = [] as Task[]
        this.rootStore.API.call("/Order/Task", {
            id: id
        }).then((response) => {
            if (response.data) {
                this.task = response.data
            }
        })
    }

    loadOrdersList = async (id) => {
        this.currentTask = id
        this.orderList = [] as Order[]
        this.rootStore.API.call("/Order/List", {
            task: id
        }).then((response) => {
            if (response.data) {
                this.orderList = response.data
                this.orderListFiltered = response.data
            }
        })
    }

    downloadFile = async (id: number, name: string) => {
        this.rootStore.API.download("/Order/DownloadFile", "ошибки_" + name, {id: id})
    }

    removeFile = async (id: number) => {
        this.rootStore.API.call("/Order/RemoveFile", {id: id}).then((response) => {
            this.loadTasks()
        })
    }

    downloadNumbers = async (id: number) => {
        this.rootStore.API.download("/Order/DownloadNumbers", "Заказ №" + id + ".xls", {id: id})
    }

    setStatus = (id: number, status: number) => {
        this.rootStore.API.call("/Order/ChangeStatus", {id: id, status: status}).then((response) => {
            if (response.data && response.data.state == "ok") {
                this.orderList.filter(x => x.id == id)[0].status_id = status
                this.orderListFiltered =  this.orderList
            } else {
                alert(response.data.message)
            }
        })
    }

    setConfirmedStatus = (id: number, status: number) => {
        this.rootStore.API.call("/Order/ChangeConfirmedStatus", {id: id, status: status}).then((response) => {
            if (response.data && response.data.state == "ok") {
                this.orderList.filter(x => x.id == id)[0].confirmed_status_id = status
                this.orderListFiltered =  this.orderList
            } else {
                alert(response.data.message)
            }
        })
    }
}

