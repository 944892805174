// Describes the supported routes, their parameters and event handlers

import {
    RouterState,
    RouterStore,
    createRouterState
} from "mobx-state-router"
import {RootStore} from "./RootStore"
import {useEffect} from "react"

const loginState = createRouterState("login")

const checkForUserLoggedIn = async (
    fromState: RouterState,
    toState: RouterState,
    routerStore: RouterStore
) => {
    const {rootStore} = routerStore.options
    const {authStore} = rootStore as RootStore

    document.body.classList.remove("__hide-all")

    /*if (typeof authStore.user.name == "undefined") {
        return loginState
    }*/
}

export const routes = [
    {
        name: "home",
        pattern: "/",
        beforeEnter: checkForUserLoggedIn,
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {rootStore} = routerStore.options
            const {quarterStore} = rootStore as RootStore
            quarterStore.getLevels()
        }
    },
    {
        name: "level",
        pattern: "/level/:level",
        beforeEnter: checkForUserLoggedIn,
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {id, level} = toState.params
            const {rootStore} = routerStore.options
            const {quarterStore} = rootStore as RootStore
            quarterStore.setLevel(Number(level))
        }
    },
    {
        name: "myOrders",
        pattern: "/my-orders",
        beforeEnter: checkForUserLoggedIn,
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {rootStore} = routerStore.options
            const {orderStore} = rootStore as RootStore
            orderStore.loadTasks()
        }
    },
    {
        name: "myOrder",
        pattern: "/my-order/:id",
        beforeEnter: checkForUserLoggedIn,
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {rootStore} = routerStore.options
            const {orderStore} = rootStore as RootStore
            const {id} = toState.params
            orderStore.loadOrdersList(id)
            orderStore.loadTask(id)
        }
    },
    {
        name: "myDoc",
        pattern: "/my-doc",
        beforeEnter: checkForUserLoggedIn,
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {rootStore} = routerStore.options
            const {documentStore} = rootStore as RootStore
            documentStore.load()
            documentStore.loadSign()
        }
    },
    {
        name: "createOrderCurrentLevels",
        pattern: "/create-order-current",
        beforeEnter: checkForUserLoggedIn,
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {rootStore} = routerStore.options
            const {quarterStore} = rootStore as RootStore
            quarterStore.getLevels()
        }
    },
    {
        name: "createOrderCurrent",
        pattern: "/create-order-current/:level",
        beforeEnter: checkForUserLoggedIn,
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {rootStore} = routerStore.options
            const {quarterStore} = rootStore as RootStore
            const {level} = toState.params
            quarterStore.setLevel( Number(level))
            quarterStore.setQuarter(0)
        }
    },
    {
        name: "createOrderCorrections",
        pattern: "/create-order-corrections",
        beforeEnter: checkForUserLoggedIn,
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {rootStore} = routerStore.options
            const {quarterStore} = rootStore as RootStore
            const {level} = toState.params

            quarterStore.loadCorrections()
        }
    },

    {
        name: "createOrderCorrectionsLevels",
        pattern: "/create-order-corrections/:id",
        beforeEnter: checkForUserLoggedIn,
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {rootStore} = routerStore.options
            const {quarterStore} = rootStore as RootStore
            const {id} = toState.params
            quarterStore.getLevels()
            quarterStore.setQuarter(Number(id))
        }
    },
    {
        name: "createOrderCorrection",
        pattern: "/create-order-corrections/:level/:id",
        beforeEnter: checkForUserLoggedIn,
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {rootStore} = routerStore.options
            const {quarterStore} = rootStore as RootStore
            const {id, level} = toState.params
            quarterStore.setLevel( Number(level))
            quarterStore.setQuarter(Number(id))
        }
    },
    {
        name: "myReferrals",
        pattern: "/my-referrals",
        beforeEnter: checkForUserLoggedIn,
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            document.body.classList.remove("__hide-all")
            const {rootStore} = routerStore.options
            const {referralStore} = rootStore as RootStore
            console.log("loadReferrals")
            referralStore.loadReferrals()
        }
    },
    {
        name: "login",
        pattern: "/login",
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            document.body.classList.remove("__hide-all")
            const {rootStore} = routerStore.options
            const {authStore} = rootStore as RootStore
            authStore.checkUser()
        }
    },
    {
        name: "error",
        pattern: "/error",
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            document.body.classList.remove("__hide-all")
            const {rootStore} = routerStore.options
        }
    },
    {
        name: "activate",
        pattern: "/activate/:hash",
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            document.body.classList.remove("__hide-all")
            const {rootStore} = routerStore.options
            const {authStore, referralStore} = rootStore as RootStore
            const {hash} = toState.params
            authStore.checkUser()
            referralStore.setHash(hash)
        }
    },
    {
        name: "recover",
        pattern: "/recover",
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {

        }
    },
    {
        name: "payment",
        pattern: "/payment",
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {

        }
    },
    {
        name: "support",
        pattern: "/support",
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {

        }
    },
    {
        name: "help",
        pattern: "/help",
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {

        }
    },
    {
        name: "helpText",
        pattern: "/help-text",
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {

        }
    },
]
