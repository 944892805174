import PageLayout from "../../components/PageLayout"
import React from "react"
import {observer} from "mobx-react"
import {useRootStore} from "../../contexts"
import {numberFormat} from "../../components/numberFormat"
import Checkbox from "@material-ui/core/Checkbox"
import Link from "../../components/Link"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"


function MyOrders() {
    const {routerStore, orderStore, authStore} = useRootStore()

    const [editable, setEditable] = React.useState(-1)
    const [editableConfirmed, setEditableConfirmed] = React.useState(-1)
    const [showFilterReferral, setShowFilterReferral] = React.useState(false)
    const [showFilterName, setShowFilterName] = React.useState(false)
    const [showFilterFirmName, setShowFilterFirmName] = React.useState(false)
    const [showFilterStatus, setShowFilterStatus] = React.useState(false)
    const [showFilterConfirmedStatus, setShowFilterConfirmedStatus] = React.useState(false)
    const [openRemove, setOpenRemove] = React.useState(false)
    const [selectedItem, setSelectedItem] = React.useState(-1)

    const handleStatus = (id: number, status: number) => {
        orderStore.setStatus(id, status)
        setEditable(-1)
    }

    const handleConfirmedStatus = (id: number, status: number) => {
        orderStore.setConfirmedStatus(id, status)
        setEditableConfirmed(-1)
    }

    return (
        <PageLayout title="" page={MyOrders}>
            <Dialog
                open={openRemove}
                onClose={() => {
                    setOpenRemove(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Удалить выбранный заказ?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Восстановить будет невозможно, если заказ не пустой и содержит накладные - они также удалятся без возможности восстановления
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenRemove(false)
                    }} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={() => {
                        orderStore.removeFile(selectedItem)
                        setOpenRemove(false)
                    }} color="secondary">
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>

            <div className="wrap content">
                <div className="container">

                    <div className="wrap d-flex ai-center title__block">
                        <h1>Мои заказы <span style={{
                            fontSize: 24,
                            color: "#999999",
                        }}>(для смены статуса обновляйте страницу раз в несколько минут)</span></h1>
                    </div>


                    <div className="wrap table__wrap">
                        <table className="">
                            <thead>
                                <tr className="table-wrap__header">
                                    <th>#</th>
                                    <th>Дата создания</th>
                                    {authStore.user.level < 2 && <th>
                                        <div className="table-wrap__filter">
                                            <div className="table-wrap__filter-name">Реферал</div>
                                            <div className="table-wrap__filter-icon" onClick={() => {
                                                orderStore.setFilterReferralTask("")
                                                setShowFilterReferral(!showFilterReferral)
                                            }}></div>
                                            <div className={"table-wrap__filter-wrap " + (showFilterReferral ? "__show" : "")}>
                                                <div>
                                                    <input
                                                        type="text"
                                                        name="search"
                                                        placeholder="Введите..."
                                                        onChange={(event) => {
                                                            orderStore.setFilterReferralTask(event.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </th>}
                                    <th>Сумма<br/>по таблице</th>
                                    <th>
                                        <div className="table-wrap__filter">
                                            <div className="table-wrap__filter-name">Подтвержденный статус</div>
                                            <div className="table-wrap__filter-icon" onClick={() => {
                                                orderStore.setFilterConfirmedStatusTask(0)
                                                setShowFilterConfirmedStatus(!showFilterConfirmedStatus)
                                            }}></div>
                                            <div className={"table-wrap__filter-wrap " + (showFilterConfirmedStatus ? "__show" : "")}>
                                                <div>
                                                    <select
                                                        defaultValue={0}
                                                        onChange={(event) => {
                                                            orderStore.setFilterConfirmedStatusTask(Number(event.target.value))
                                                        }}
                                                    >
                                                        <option value={0} selected={!showFilterConfirmedStatus}>Все</option>
                                                        <option value={1}>Не оплачен</option>
                                                        <option value={2}>Оплачен</option>
                                                        <option value={3}>Частично оплачен</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-wrap__filter">
                                            <div className="table-wrap__filter-name">Статус</div>
                                            <div className="table-wrap__filter-icon" onClick={() => {
                                                orderStore.setFilterStatusTask(0)
                                                setShowFilterStatus(!showFilterStatus)
                                            }}></div>
                                            <div className={"table-wrap__filter-wrap " + (showFilterStatus ? "__show" : "")}>
                                                <div>
                                                    <select
                                                        defaultValue={0}
                                                        onChange={(event) => {
                                                            orderStore.setFilterStatusTask(Number(event.target.value))
                                                        }}
                                                    >
                                                        <option value={0} selected={!showFilterStatus}>Все</option>
                                                        <option value={1}>Не оплачен</option>
                                                        <option value={2}>Оплачен</option>
                                                        <option value={3}>Частично оплачен</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {orderStore.tasksFiltered.length > 0 && orderStore.tasksFiltered.map(item => {
                                    return <tr
                                        style={{
                                            cursor: item.status == "ready" ? "pointer" : "default",
                                        }}
                                        key={item.id}
                                        onClick={() => {
                                            if (item.status == "ready" ) {
                                                routerStore.goTo("myOrder", {
                                                    params: {
                                                        id: item.id
                                                    }
                                                })
                                            }
                                        }}
                                    >
                                        <td>{item.id}</td>
                                        <td>{item.date_create}</td>
                                        {authStore.user.level < 2 &&
                                        <td>
                                            {item.parents.length > 0 && <>
                                                {item.parents.map(it => {
                                                    if (it == authStore.user.id) {
                                                        return "Я <- "
                                                    } else {
                                                        return item.parentsNamed.filter(x => x.id == it)[0].name + " <- "
                                                    }
                                                })}
                                                {item.referral}
                                            </>}
                                            {item.parents.length == 0 && <>Я</>}
                                        </td>
                                        }
                                        {item.status == "ready" && <>
                                            <td>{numberFormat(item.summ)}</td>
                                            <td>
                                                {item.cnt_pay_confirmed == 0 &&
                                                <span className="table-wrap__tag table-wrap__tag-notpaid">Не оплачено</span>
                                                }
                                                {item.cnt_pay_confirmed > 0 && item.cnt > item.cnt_pay_confirmed &&
                                                <span className="table-wrap__tag table-wrap__tag-halfpaid">Частично оплачено</span>
                                                }
                                                {item.cnt > 0 && item.cnt == item.cnt_pay_confirmed &&
                                                <span className="table-wrap__tag table-wrap__tag-paid">Оплачено</span>
                                                }
                                            </td>
                                            <td>
                                                {item.cnt_pay == 0 &&
                                                <span className="table-wrap__tag table-wrap__tag-notpaid">Не оплачено</span>
                                                }
                                                {item.cnt_pay > 0 && item.cnt > item.cnt_pay &&
                                                <span className="table-wrap__tag table-wrap__tag-halfpaid">Частично оплачено</span>
                                                }
                                                {item.cnt > 0 && item.cnt == item.cnt_pay &&
                                                <span className="table-wrap__tag table-wrap__tag-paid">Оплачено</span>
                                                }
                                            </td>

                                        </>}

                                        {item.status == "queded" && <>
                                            <td>
                                                <p className="text__small text__color-grey">{item.file}</p>
                                            </td>
                                            <td style={{color: "#caca00"}}>
                                                Ожидает проверки
                                            </td>
                                            <td></td>
                                        </>}

                                        {item.status == "process" && <>
                                            <td style={{color: "#017c00"}}>
                                                <p className="text__small text__color-grey">{item.file}</p>
                                            </td>
                                            <td>
                                                Обрабатывается
                                            </td>
                                            <td></td>
                                        </>}

                                        {item.status == "uploadError" && <>
                                            <td style={{color: "#ff0000"}}>
                                                <p className="text__small text__color-grey">{item.file}</p>
                                            </td>
                                            <td>
                                                Ошибка загрузки
                                            </td>
                                            <td>
                                                {item.user_id == authStore.user.id && <>
                                                    <span
                                                        style={{cursor: "pointer"}}
                                                        onClick={() => {
                                                            setSelectedItem(item.id)
                                                            setOpenRemove(true)
                                                        }}
                                                    >
                                                        <span>Удалить</span>
                                                    </span></>}
                                            </td>
                                        </>}
                                        {item.status == "error" && <>
                                            <td style={{color: "#ff0000"}}>
                                                Ошибка обработки
                                            </td>
                                            <td>
                                                <p className="text__small text__color-grey">{item.file}</p>
                                            </td>
                                            <td>
                                                {item.user_id == authStore.user.id && <>
                                                    <span
                                                        style={{cursor: "pointer"}}
                                                        onClick={() => {
                                                            setSelectedItem(item.id)
                                                            setOpenRemove(true)
                                                        }}
                                                    >
                                                        <span>Удалить</span>
                                                    </span>&nbsp;&nbsp;&nbsp;
                                                    <span
                                                        style={{cursor: "pointer"}}
                                                        onClick={() => {
                                                            orderStore.downloadFile(item.id, item.file)
                                                        }}
                                                    >
                                                        <span>Скачать</span>
                                                    </span>
                                                </>}
                                            </td>
                                        </>}
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </PageLayout>
    )
}

export default observer(MyOrders)
