import PageLayout from "../../components/PageLayout"
import React from "react"
import {observer} from "mobx-react"
import {useRootStore} from "../../contexts"
import {numberFormat} from "../../components/numberFormat"
import Checkbox from "@material-ui/core/Checkbox"
import Link from "../../components/Link"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"


function MyOrder() {
    const {routerStore, orderStore, authStore} = useRootStore()

    const [editable, setEditable] = React.useState(-1)
    const [editableConfirmed, setEditableConfirmed] = React.useState(-1)
    const [showFilterReferral, setShowFilterReferral] = React.useState(false)
    const [showFilterName, setShowFilterName] = React.useState(false)
    const [showFilterFirmName, setShowFilterFirmName] = React.useState(false)
    const [showFilterStatus, setShowFilterStatus] = React.useState(false)
    const [showFilterConfirmedStatus, setShowFilterConfirmedStatus] = React.useState(false)
    const [openRemove, setOpenRemove] = React.useState(false)
    const [openRemoveRow, setOpenRemoveRow] = React.useState(false)


    const handleStatus = (id: number, status: number) => {
        orderStore.setStatus(id, status)
        setEditable(-1)
    }

    const handleConfirmedStatus = (id: number, status: number) => {
        orderStore.setConfirmedStatus(id, status)
        setEditableConfirmed(-1)
    }

    return (
        <PageLayout title="" page={MyOrder}>
            {typeof orderStore.task.id != "undefined" &&
            <Dialog
                open={openRemove}
                onClose={() => {
                    setOpenRemove(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Удалить выбранный заказ?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Восстановить будет невозможно, если заказ не пустой и содержит накладные - они также удалятся без возможности восстановления
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenRemove(false)
                    }} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={() => {
                        orderStore.removeFile(orderStore.task.id)
                        setOpenRemove(false)
                        routerStore.goTo("myOrders")
                    }} color="secondary">
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>
            }

            <div className="wrap content">
                <div className="container">

                    {typeof orderStore.task.id != "undefined" &&
                    <div className="wrap d-flex ai-center title__block">
                        <h1>Заказ #{orderStore.task.id} от {orderStore.task.date_create}</h1>
                        <div className="wrap button-block__content mtop0">
                            <div>
                                <p>Дата: <span style={{fontWeight: "900"}}>{orderStore.task.date_create}</span></p>
                                <p>Реферал: <span style={{fontWeight: "900"}}>
                                    {orderStore.task.parents.length > 0 && <>
                                        {orderStore.task.parents.map(it => {
                                            if (it == authStore.user.id) {
                                                return "Я <- "
                                            } else {
                                                return orderStore.task.parentsNamed.filter(x => x.id == it)[0].name + " <- "
                                            }
                                        })}
                                        {orderStore.task.referral}
                                    </>}
                                    {orderStore.task.parents.length == 0 && <>Я</>}</span>
                                </p>
                                {orderStore.task.user_id == authStore.user.id && orderStore.task.cnt_pay_confirmed == 0 && orderStore.task.cnt_pay == 0 && <>
                                    <hr/>
                                    <span
                                        style={{cursor: "pointer"}}
                                        onClick={() => {
                                            setOpenRemove(true)
                                        }}
                                    >
                                        <span>Удалить</span>
                                    </span>&nbsp;&nbsp;&nbsp;</>}

                                <span
                                    style={{cursor: "pointer"}}
                                    onClick={() => {
                                        orderStore.downloadNumbers(orderStore.task.id)
                                    }}
                                >
                                    <span>Скачать реестр</span>
                                </span>
                            </div>
                        </div>
                    </div>}

                    {authStore.user.level < 2 && <>
                        <div className="wrap table__wrap">
                            <table className="">
                                <thead>
                                    <tr className="table-wrap__header">
                                        <th></th>
                                        <th>#</th>
                                        <th>
                                            <div className="table-wrap__filter">
                                                <div className="table-wrap__filter-name">Ваша фирма</div>
                                                <div className="table-wrap__filter-icon" onClick={() => {
                                                    orderStore.setFilterName("")
                                                    setShowFilterName(!showFilterName)
                                                }}></div>
                                                <div className={"table-wrap__filter-wrap " + (showFilterName ? "__show" : "")}>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            name="search"
                                                            placeholder="Введите..."
                                                            onChange={(event) => {
                                                                orderStore.setFilterName(event.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="table-wrap__filter">
                                                <div className="table-wrap__filter-name">Наша фирма</div>
                                                <div className="table-wrap__filter-icon" onClick={() => {
                                                    orderStore.setFilterFirmName("")
                                                    setShowFilterFirmName(!showFilterFirmName)
                                                }}></div>
                                                <div className={"table-wrap__filter-wrap " + (showFilterFirmName ? "__show" : "")}>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            name="search"
                                                            placeholder="Введите..."
                                                            onChange={(event) => {
                                                                orderStore.setFilterFirmName(event.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                        <th>Сумма<br/>по таблице</th>
                                        <th>Мне должны оплатить</th>
                                        <th>Я должен оплатить</th>
                                        <th>
                                            <div className="table-wrap__filter">
                                                <div className="table-wrap__filter-name">Подтвержденный статус</div>
                                                <div className="table-wrap__filter-icon" onClick={() => {
                                                    orderStore.setFilterConfirmedStatus(0)
                                                    setShowFilterConfirmedStatus(!showFilterConfirmedStatus)
                                                }}></div>
                                                <div className={"table-wrap__filter-wrap " + (showFilterConfirmedStatus ? "__show" : "")}>
                                                    <div>
                                                        <select
                                                            defaultValue={0}
                                                            onChange={(event) => {
                                                                orderStore.setFilterConfirmedStatus(Number(event.target.value))
                                                            }}
                                                        >
                                                            <option value={0} selected={!showFilterConfirmedStatus}>Все</option>
                                                            <option value={1}>Не оплачен</option>
                                                            <option value={2}>Оплачен</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="table-wrap__filter">
                                                <div className="table-wrap__filter-name">Статус</div>
                                                <div className="table-wrap__filter-icon" onClick={() => {
                                                    orderStore.setFilterStatus(0)
                                                    setShowFilterStatus(!showFilterStatus)
                                                }}></div>
                                                <div className={"table-wrap__filter-wrap " + (showFilterStatus ? "__show" : "")}>
                                                    <div>
                                                        <select
                                                            defaultValue={0}
                                                            onChange={(event) => {
                                                                orderStore.setFilterStatus(Number(event.target.value))
                                                            }}
                                                        >
                                                            <option value={0} selected={!showFilterStatus}>Все</option>
                                                            <option value={1}>Не оплачен</option>
                                                            <option value={2}>Оплачен</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className="">
                                    {orderStore.orderListFiltered.length > 0 && orderStore.orderListFiltered.map(item => {
                                        return <tr key={item.id}>
                                            <td>
                                                <Checkbox
                                                    color="primary"
                                                    checked={item.checked || false}
                                                    onChange={(event) => {
                                                        orderStore.setChecked(item.id, event.target.checked)
                                                    }}
                                                />
                                            </td>
                                            <td>{item.id}</td>
                                            <td>
                                                <div className="wrap d-flex fd-column table-wrap__rowspan">
                                                    <div>{item.name}</div>
                                                    <div>ИНН {item.inn}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="wrap d-flex fd-column table-wrap__rowspan">
                                                    <div>{item.firm_name}</div>
                                                    <div>ИНН {item.firm_inn}</div>
                                                </div>
                                            </td>
                                            <td>{numberFormat(item.summ)}</td>
                                            <td>
                                                {numberFormat(item.need_pay)}
                                            </td>
                                            <td>

                                                {item.parents.length > 0 && item.parentsNamed.filter(x => x.need_pay != null).length > 0 && <>
                                                    {numberFormat(item.parentsNamed.filter(x => x.need_pay != null)[0].need_pay)}
                                                </>}
                                                {(item.parents.length == 0 || item.parentsNamed.filter(x => x.need_pay != null).length == 0) && <>
                                                    {numberFormat(item.need_pay)}
                                                </>}
                                            </td>
                                            <td>
                                                {authStore.user.level > 0 && <>
                                                    {item.confirmed_status_id == 1 &&
                                                    <span className="table-wrap__tag table-wrap__tag-notpaid">Не оплачено</span>
                                                    }
                                                    {item.confirmed_status_id == 2 &&
                                                    <span className="table-wrap__tag table-wrap__tag-paid">Оплачено</span>
                                                    }
                                                </>}

                                                {authStore.user.level == 0 && <>
                                                    {item.confirmed_status_id == 1 &&
                                                    <span
                                                        className="table-wrap__tag table-wrap__tag-notpaid"
                                                        style={{cursor: "pointer"}}
                                                        onClick={() => {
                                                            setEditableConfirmed(editableConfirmed != item.id ? item.id : -1)
                                                        }}
                                                    >Не оплачено &#9660;</span>
                                                    }
                                                    {item.confirmed_status_id == 2 &&
                                                    <span
                                                        className="table-wrap__tag table-wrap__tag-paid"
                                                        style={{cursor: "pointer"}}
                                                        onClick={() => {
                                                            setEditableConfirmed(editableConfirmed != item.id ? item.id : -1)
                                                        }}
                                                    >Оплачено &#9660;</span>
                                                    }
                                                </>}

                                                <div style={{position: "relative"}}>
                                                    {editableConfirmed == item.id && <div className={"status-bar"}>
                                                        <span
                                                            className={"table-wrap__tag table-wrap__tag-notpaid" + (item.confirmed_status_id == 1 ? " bordered" : "")}
                                                            style={{cursor: "pointer"}}
                                                            onClick={() => handleConfirmedStatus(item.id, 1)}
                                                        >Не оплачено</span>

                                                        <span
                                                            className={"table-wrap__tag table-wrap__tag-paid" + (item.confirmed_status_id == 2 ? " bordered" : "")}
                                                            style={{cursor: "pointer"}}
                                                            onClick={() => handleConfirmedStatus(item.id, 2)}
                                                        >Оплачено</span>
                                                    </div>}
                                                </div>
                                            </td>
                                            <td>
                                                {(item.user_id != authStore.user.id || item.confirmed_status_id == 2) && <>
                                                    {item.status_id == 1 &&
                                                    <span className="table-wrap__tag table-wrap__tag-notpaid">Не оплачено</span>
                                                    }
                                                    {item.status_id == 2 &&
                                                    <span className="table-wrap__tag table-wrap__tag-paid">Оплачено</span>
                                                    }
                                                </>}

                                                {item.user_id == authStore.user.id && item.confirmed_status_id == 1 && <>
                                                    {item.status_id == 1 &&
                                                    <span
                                                        className="table-wrap__tag table-wrap__tag-notpaid"
                                                        style={{cursor: "pointer"}}
                                                        onClick={() => setEditable(editable != item.id ? item.id : -1)}
                                                    >Не оплачено &#9660;</span>
                                                    }
                                                    {item.status_id == 2 &&
                                                    <span
                                                        className="table-wrap__tag table-wrap__tag-paid"
                                                        style={{cursor: "pointer"}}
                                                        onClick={() => setEditable(editable != item.id ? item.id : -1)}
                                                    >Оплачено &#9660;</span>
                                                    }
                                                    <div style={{position: "relative"}}>
                                                        {editable == item.id && <div className={"status-bar"}>
                                                            <span
                                                                className={"table-wrap__tag table-wrap__tag-notpaid" + (item.status_id == 1 ? " bordered" : "")}
                                                                style={{cursor: "pointer"}}
                                                                onClick={() => handleStatus(item.id, 1)}
                                                            >Не оплачено</span>

                                                            <span
                                                                className={"table-wrap__tag table-wrap__tag-paid" + (item.status_id == 2 ? " bordered" : "")}
                                                                style={{cursor: "pointer"}}
                                                                onClick={() => handleStatus(item.id, 2)}
                                                            >Оплачено</span>
                                                        </div>}
                                                    </div>
                                                </>}
                                            </td>
                                            <td>
                                                {item.confirmed_status_id == 1 && item.status_id == 1 && item.user_id == authStore.user.id && <>
                                                    <span
                                                        onClick={() => {
                                                            setOpenRemoveRow(true)
                                                        }}
                                                    >
                                                        <span>Удалить</span>
                                                    </span>
                                                </>}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            {orderStore.orderListFiltered.length > 0 && orderStore.orderListFiltered.filter(x => x.checked).length > 0 && <span>
                                                Выбрано {orderStore.orderListFiltered.filter(x => x.checked).length}
                                            </span>}
                                            {orderStore.orderListFiltered.length > 0 && orderStore.orderListFiltered.filter(x => x.checked).length == 0 && <span>
                                                Итого
                                            </span>}
                                        </td>
                                        <td></td>
                                        <td>
                                            {orderStore.orderListFiltered.length > 0 && orderStore.orderListFiltered.filter(x => x.checked).length > 0 && <span>
                                                {numberFormat(orderStore.orderListFiltered.filter(x => x.checked).map(x => x.summ).reduce((a, b) => a + b))}
                                            </span>}
                                            {orderStore.orderListFiltered.length > 0 && orderStore.orderListFiltered.filter(x => x.checked).length == 0 && <span>
                                                {numberFormat(orderStore.orderListFiltered.map(x => x.summ).reduce((a, b) => a + b))}
                                            </span>}
                                        </td>
                                        <td>
                                            {orderStore.orderListFiltered.length > 0 && orderStore.orderListFiltered.filter(x => x.checked).length > 0 && <span>
                                                {numberFormat(orderStore.orderListFiltered.filter(x => x.checked).map(x => x.need_pay).reduce((a, b) => a + b))}
                                            </span>}
                                            {orderStore.orderListFiltered.length > 0 && orderStore.orderListFiltered.filter(x => x.checked).length == 0 && <span>
                                                {numberFormat(orderStore.orderListFiltered.map(x => x.need_pay).reduce((a, b) => a + b))}
                                            </span>}
                                        </td>
                                        <td>
                                            {orderStore.orderListFiltered.length > 0 && orderStore.orderListFiltered.filter(x => x.checked).length > 0 && <span>
                                                {numberFormat(orderStore.orderListFiltered.filter(x => x.checked)
                                                    .map(
                                                        x => x.parentsNamed.filter(x => x.need_pay != null).length > 0
                                                            ? x.parentsNamed.filter(x => x.need_pay != null)[0].need_pay
                                                            : x.need_pay
                                                    ).reduce((a, b) => a + b)
                                                )}
                                            </span>}

                                            {orderStore.orderListFiltered.length > 0 && orderStore.orderListFiltered.filter(x => x.checked).length == 0 && <span>
                                                {numberFormat(orderStore.orderListFiltered
                                                    .map(
                                                        x => x.parentsNamed.filter(x => x.need_pay != null).length > 0
                                                            ? x.parentsNamed.filter(x => x.need_pay != null)[0].need_pay
                                                            : x.need_pay
                                                    ).reduce((a, b) => a + b)
                                                )}
                                            </span>}

                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </>}

                    {authStore.user.level == 2 && <>
                        <div className="wrap table__wrap">
                            <table className="">
                                <tbody className="">
                                    <tr className="table-wrap__header">
                                        <td></td>
                                        <td>№ заявки</td>
                                        <td>Ваша фирма</td>
                                        <td>Наша фирма</td>
                                        <td>Таблица<br/>с номерами</td>
                                        <td>Подтвержденный статус</td>
                                        <td>Статус</td>
                                    </tr>

                                    {orderStore.orderListFiltered.length > 0 && orderStore.orderListFiltered.map(item => {
                                        return <tr>
                                            <td></td>
                                            <td>{item.id}</td>
                                            <td>
                                                <div className="wrap d-flex fd-column table-wrap__rowspan">
                                                    <div>{item.name}</div>
                                                    <div>ИНН {item.inn}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="wrap d-flex fd-column table-wrap__rowspan">
                                                    <div>{item.firm_name}</div>
                                                    <div>ИНН {item.firm_inn}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <span
                                                    className="link link__text-bottom"
                                                    onClick={() => {
                                                        orderStore.downloadNumbers(item.id)
                                                    }}
                                                >
                                                    <span>Скачать</span>
                                                </span>
                                            </td>
                                            <td>
                                                {item.confirmed_status_id == 1 &&
                                                <span className="table-wrap__tag table-wrap__tag-notpaid">Не оплачено</span>
                                                }
                                                {item.confirmed_status_id == 2 &&
                                                <span className="table-wrap__tag table-wrap__tag-paid">Оплачено</span>
                                                }
                                            </td>
                                            <td>
                                                {(item.user_id != authStore.user.id || item.confirmed_status_id == 2) && <>
                                                    {item.status_id == 1 &&
                                                    <span className="table-wrap__tag table-wrap__tag-notpaid">Не оплачено</span>
                                                    }
                                                    {item.status_id == 2 &&
                                                    <span className="table-wrap__tag table-wrap__tag-paid">Оплачено</span>
                                                    }
                                                </>}

                                                {item.user_id == authStore.user.id && item.confirmed_status_id == 1 && <>
                                                    {item.status_id == 1 &&
                                                    <span
                                                        className="table-wrap__tag table-wrap__tag-notpaid"
                                                        style={{cursor: "pointer"}}
                                                        onClick={() => setEditable(editable != item.id ? item.id : -1)}
                                                    >Не оплачено &#9660;</span>
                                                    }
                                                    {item.status_id == 2 &&
                                                    <span
                                                        className="table-wrap__tag table-wrap__tag-paid"
                                                        style={{cursor: "pointer"}}
                                                        onClick={() => setEditable(editable != item.id ? item.id : -1)}
                                                    >Оплачено &#9660;</span>
                                                    }
                                                    <div style={{position: "relative"}}>
                                                        {editable == item.id && <div className={"status-bar"}>
                                                            <span
                                                                className={"table-wrap__tag table-wrap__tag-notpaid" + (item.status_id == 1 ? " bordered" : "")}
                                                                style={{cursor: "pointer"}}
                                                                onClick={() => handleStatus(item.id, 1)}
                                                            >Не оплачено</span>

                                                            <span
                                                                className={"table-wrap__tag table-wrap__tag-paid" + (item.status_id == 2 ? " bordered" : "")}
                                                                style={{cursor: "pointer"}}
                                                                onClick={() => handleStatus(item.id, 2)}
                                                            >Оплачено</span>
                                                        </div>}
                                                    </div>
                                                </>}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>}

                </div>
            </div>

        </PageLayout>
    )
}

export default observer(MyOrder)
