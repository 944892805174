import PageLayout from "../../components/PageLayout"
import React, {useEffect} from "react"
import {observer} from "mobx-react"
import {useRootStore} from "../../contexts"
import Link from "../../components/Link/Link"

function CreateOrderCorrections() {
    const {routerStore, quarterStore} = useRootStore()
    const [openDrawer, setOpenDrawer] = React.useState(false)

    useEffect(() => {
        document.body.classList.toggle("__hide-all", openDrawer)
    }, [openDrawer])

    return (
        <PageLayout title="" page={CreateOrderCorrections}>
            <div className="wrap content">
                <div className="container">
                    <div className="wrap d-flex ai-center title__block">
                        <h1>Уточнения</h1>
                    </div>
                    <div className="wrap d-flex item-grid item-grid-3 button__block">
                        {quarterStore.quarters && quarterStore.quarters.map(item => {
                            return <div className="item">
                                <Link route={"createOrderCorrectionsLevels"} options={{
                                    params: {
                                        id: item.id,
                                    }
                                }} className={"wrap d-flex fd-column"}>
                                    <div className="wrap button-block__content">
                                        <p>{item.quarter} квартал {item.year}</p>
                                    </div>
                                </Link>
                            </div>
                        })}

                        {!quarterStore.quarters && <div>Уточнений пока нет</div>}
                    </div>
                </div>
            </div>
        </PageLayout>
    )
}

export default observer(CreateOrderCorrections)
