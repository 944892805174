import {ErrorBoundary, Loading} from "@react-force/core"
import React, {Suspense} from "react"
import {RouterContext, RouterView} from "mobx-state-router"
import {ThemeProvider, createTheme} from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import {RootStoreContext} from "./contexts"
import {initApp} from "./initApp"
import red from "@material-ui/core/colors/red"
import {viewMap} from "./stores/viewMap"
import type from "@material-ui/lab/themeAugmentation"
import {CircularProgress} from "@material-ui/core"


const rootStore = initApp()

function App() {
    const {routerStore} = rootStore

    const theme = createTheme({
        overrides: {
            MuiPaginationItem: {
                root: {
                    margin: 0,
                    borderRadius: 0,
                },
                ellipsis: {
                    lineHeight: "32px",
                },
                page: {
                    backgroundColor: "rgba(0,134,255,.32)",
                    color: "var(--basic-blue-dark)",

                    "&.Mui-selected": {
                        color: "var(--basic-blue-dark)",
                        background: "rgba(0,134,255,.32)",
                    }
                },

            }
        }
    })

    return (
        <ThemeProvider theme={theme}>
            <Suspense fallback={<Loading/>}>
                <RootStoreContext.Provider value={rootStore}>
                    <RouterContext.Provider value={routerStore}>
                        <RouterView viewMap={viewMap}/>
                    </RouterContext.Provider>
                </RootStoreContext.Provider>
            </Suspense>
        </ThemeProvider>
    )
}

export default App
