import HomePage from "../pages/HomePage"
import React from "react"
import MyOrders from "../pages/MyOrders/MyOrders"
import MyOrder from "../pages/MyOrder/MyOrder"
import MyDoc from "../pages/MyDoc/MyDoc"
import CreateOrderCurrent from "../pages/CreateOrderCurrent/CreateOrderCurrent"
import CreateOrderCorrections from "../pages/CreateOrderCorrections/CreateOrderCorrections"
import LoginPage from "../pages/LoginPage/LoginPage"
import ErrorPage from "../pages/ErrorPage/ErrorPage"
import CreateOrderCorrection from "../pages/CreateOrderCorrection/CreateOrderCorrection"
import CreateOrderCurrentLevelsPage from "../pages/CreateOrderCurrentLevelsPage/CreateOrderCurrentLevelsPage"
import CreateOrderCorrectionsLevelsPage from "../pages/CreateOrderCorrectionsLevelsPage/CreateOrderCorrectionsLevelsPage"
import MyReferrals from "../pages/MyReferrals"
import Activate from "../pages/Activate"
import RecoverPage from "../pages/RecoverPage"
import LevelPage from "../pages/LevelPage"
import Payment from "../pages/Payment"
import Support from "../pages/Support"
import Help from "../pages/Help"
import HelpText from "../pages/HelpText"


export const viewMap = {
    home: <HomePage />,
    myOrders: <MyOrders/>,
    myOrder: <MyOrder/>,
    myDoc: <MyDoc/>,
    createOrderCurrent: <CreateOrderCurrent/>,
    createOrderCorrections: <CreateOrderCorrections/>,
    createOrderCorrection: <CreateOrderCorrection/>,
    login: <LoginPage/>,
    error: <ErrorPage/>,
    myReferrals: <MyReferrals/>,
    activate: <Activate/>,
    recover: <RecoverPage/>,
    level: <LevelPage/>,
    payment: <Payment/>,
    support: <Support/>,
    help: <Help/>,
    helpText: <HelpText/>,
    createOrderCurrentLevels: <CreateOrderCurrentLevelsPage/>,
    createOrderCorrectionsLevels: <CreateOrderCorrectionsLevelsPage/>,
}
